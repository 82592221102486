import React from "react";
import "./App.css";
import { FaMobileAlt, FaWhatsapp } from "react-icons/fa"; // Importa FaWhatsapp
import backgroundImage from "./assets/images/background-image.jpg";
import logoImage from "./assets/images/logo-image.png";

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <header className="App-header">
        <img src={logoImage} alt="Logo" className="logo" />
      </header>
      <div className="content">
        <div className="left-content">
          <div className="text-section">
            <h1>¿Necesitas vender tu vivienda?</h1>
            <p>
              Te ayudamos y asesoramos en la venta.
              <br />
              Consigue la mejor valoración.
              <br />
              Nosotros nos encargamos de todo.
            </p>
          </div>
          <div className="call-to-action">
            <h2>Vende TU VIVIENDA</h2>
            <p>LLama y te atenderá un agente especializado</p>
            <a href="tel:+1234567890" className="phone-number">
              <FaMobileAlt className="phone-icon" /> 624 257 884
            </a>
            {/* Ícono de WhatsApp con enlace */}
            <a
              href="https://wa.me/624257884"
              className="phone-number"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="phone-icon" /> WhatsApp
            </a>
          </div>
        </div>
        <div className="iframe-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSftDwxQb_9_P2C4hPJM7FzdWcobLeHA1XbSo-_-iV2ulPN3pw/viewform?embedded=true"
            title="Formulario de Contacto"
            style={{ transform: "scale(0.8)", transformOrigin: "0 0" }}
          >
            Cargando…
          </iframe>
        </div>
      </div>
      <footer className="footer">
        <p>
          Contáctanos:{" "}
          <a href="mailto:info@vendetuvivienda.es">info@vendetuvivienda.es</a>
        </p>
        <p>© 2024 Tu Empresa. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
